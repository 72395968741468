
import { FormValidations } from "@/mixins/form-validations";
import { Navigation } from "@/mixins/navigation";
import { Permissions } from "@/mixins/permissions";
import PageTitle from "@/components/General/PageTitle.vue";
import { Penalty, PenaltyUser } from "@/models/penalty.interface";
import Component, { mixins } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import Loader from "@/components/General/Loader.vue";
import Empty from "@/components/General/Empty.vue";
import { Notification } from "@/models/notification.interface";
import { Status } from "@/models/status.interface";
import PenaltyForm from "@/components/Layout/Backoffice/PenaltyForm.vue";
import { StyleCheck } from "@/mixins/style-check";
import BarSearch from "@/components/Input/BarSearch.vue";
import { CustomDate } from "@/mixins/custom-date";
import DotsMenu from "@/components/General/DotsMenu.vue";
@Component({
  components: { PageTitle, Loader, Empty, PenaltyForm, BarSearch, DotsMenu },
})
export default class Penalties extends mixins(
  FormValidations,
  Navigation,
  Permissions,
  StyleCheck,
  CustomDate
) {
  $refs!: {
    penaltyForm: HTMLFormElement;
  };
  loader = false;
  loading = false;
  loadingDT = false;
  dialog = false;
  dialogDelete = false;
  status = [this.$constants.STATUS.ACTIVE, this.$constants.STATUS.INACTIVE];
  defaultPenalty: PenaltyUser = {
    id: undefined,
    penalty_reason: "",
    start_date: "",
    fk_penalty: NaN,
  };
  penalty: PenaltyUser = { ...this.defaultPenalty };
  cancelPenalty = { removal_reason: "" };
  search = "";
  options = {};

  pagination = {
    itemsPerPage: parseInt(process.env.VUE_APP_DEFAULT_PAGINATION),
    totalItems: 0,
    page: 1,
  };

  async searchPenaltyByText() {
    this.loadingDT = true;

    await this.getPenalties(
      this.pagination.page,
      this.pagination.itemsPerPage == -1
        ? this.pagination.totalItems
        : this.pagination.itemsPerPage,
      this.search
    );
    this.loadingDT = false;
  }

  @Watch("options", { deep: true })
  private async setItems(pagination) {
    this.loadingDT = true;
    this.pagination.itemsPerPage = pagination.itemsPerPage;

    await this.getPenalties(
      pagination.page,
      pagination.itemsPerPage == -1
        ? this.pagination.totalItems
        : this.pagination.itemsPerPage,
      this.search
    );
    this.loadingDT = false;
  }

  private async getPenalties(
    page: number,
    size: number,
    text = ""
  ): Promise<void> {
    await this.$store
      .dispatch("penalties/getPenalties", { page, size, text })
      .catch(() => {
        const Error: Notification = {
          message: this.$tc("Penalties.fetchError.penalties"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };

        this.$store.dispatch("notifications/showNotification", Error);
      });
  }

  private async created() {
    this.loader = true;
    await this.getPenalties(
      this.pagination.page,
      this.pagination.itemsPerPage,
      this.search
    );
    await this.getPenaltyTypes();
    this.loader = false;
  }
  private async getPenaltyTypes() {
    await this.$store.dispatch("penalties/getPenaltyTypes").catch(() => {
      const Error: Notification = {
        message: this.$tc("Penalties.fetchError.types"),
        timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
        top: true,
        bottom: false,
        left: false,
        right: false,
        currentPath: this.$route.fullPath,
        error: true,
      };
      this.$store.dispatch("notifications/showNotification", Error);
    });
  }

  get headers() {
    return [
      {
        text: this.$tc("Penalties.headers.client"),
        value: "client",
        align: "center",
        sortable: false,
      },
      {
        text: this.$tc("Penalties.headers.grade"),
        value: "grade",
        align: "center",
        sortable: false,
      },
      {
        text: this.$tc("Penalties.headers.expiration_date"),
        value: "expiration_date",
        align: "center",
        sortable: false,
      },
      {
        text: this.$tc("Penalties.headers.status"),
        value: "status",
        align: "center",
        sortable: false,
      },
      {
        text: this.$tc("Penalties.headers.actions"),
        value: "actions",
        sortable: false,
        align: "center",
      },
    ];
  }

  get penalties(): Penalty[] {
    let response = this.$store.getters["penalties/getPenalties"];
    this.pagination.totalItems = response.totalItems;
    this.pagination.page = parseInt(response.currentPage);

    if (response?.penalties) {
      return response.penalties;
    } else {
      return [];
    }
  }
  penaltyDetail(penalty: PenaltyUser) {
    this.$router.push(`/staff/penalties/${penalty.id}/detail`);
  }
  async editPenalty(penalty: PenaltyUser) {
    this.penalty = { ...penalty };
    this.penalty.status = { ...penalty.status } as Status;
    this.dialog = true;
  }

  async deletePenaltyConfirm(penalty: PenaltyUser) {
    this.penalty = { ...penalty };
    this.dialogDelete = true;
  }

  async deletePenalty() {
    this.loading = true;
    if (this.$refs.penaltyForm.validate()) {
      await this.$store
        .dispatch("penalties/updatePenalty", {
          id: this.penalty.id,
          removal_reason: this.cancelPenalty.removal_reason,
          status: this.$constants.STATUS.CANCELED,
        })
        .then(async () => {
          const Success: Notification = {
            message: this.$tc("Penalties.deleteSuccess"),
            timeout: this.$constants.NOTIFICATION_TIMEOUT.SUCCESS,
            top: true,
            bottom: false,
            left: false,
            right: false,
            currentPath: this.$route.fullPath,
            error: false,
          };

          this.$store.dispatch("notifications/showNotification", Success);
          await this.getPenalties(
            this.pagination.page,
            this.pagination.itemsPerPage == -1
              ? this.pagination.totalItems
              : this.pagination.itemsPerPage,
            this.search
          );
          this.loading = false;
          this.closeDelete();
        })
        .catch(() => {
          const Error: Notification = {
            message: this.$tc("Penalties.deleteError"),
            timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
            top: true,
            bottom: false,
            left: false,
            right: false,
            currentPath: this.$route.fullPath,
            error: true,
          };

          this.$store.dispatch("notifications/showNotification", Error);

          this.loading = false;
        });
    } else {
      this.loading = false;
    }
  }

  private close() {
    this.dialog = false;
    this.penalty = Object.assign(this.penalty, this.defaultPenalty);
    this.getPenalties(
      this.pagination.page,
      this.pagination.itemsPerPage == -1
        ? this.pagination.totalItems
        : this.pagination.itemsPerPage,
      this.search
    );
  }

  private closeDelete() {
    this.dialogDelete = false;
    this.penalty = Object.assign(this.penalty, this.defaultPenalty);
    this.cancelPenalty = Object.assign(this.cancelPenalty, {
      removal_reason: "",
    });
  }
}
